import * as React from 'react';
import { Box, Flex } from '@chakra-ui/react';

export default function Rainbow() {
  return (
    <Flex flexDir="row">
      <Box bg="reddishOrange" h={{ base: '3px', md: '11px' }} w="20%" />
      <Box bg="macaroniAndCheese" h={{ base: '3px', md: '11px' }} w="20%" />
      <Box bg="seafoamBlue" h={{ base: '3px', md: '11px' }} w="20%" />
      <Box bg="skyBlue" h={{ base: '3px', md: '11px' }} w="20%" />
      <Box bg="softBlue" h={{ base: '3px', md: '11px' }} w="20%" />
    </Flex>
  );
}
