import * as React from 'react';
import { AnalyticsContext } from 'src/helpers/analytics';

export default function useAnalytics() {
  const result = React.useContext(AnalyticsContext);

  if (!result) throw new Error('Context used outside of its Provider!');

  return result;
}
