import * as React from 'react';
import { Box, Container, Flex, Link, Text } from '@chakra-ui/react';
import Image from 'next/image';

import MobileAppScreenshots from 'public/images/mobileAppScreenshots.png';

import { APP_STORE_LINK, PLAY_STORE_LINK } from 'src/helpers/constants';
import useAnalytics from 'src/hooks/analytics';

import { AppStoreBadge, PlayStoreBadge, PlusIcon } from './icons';

const MobileAppScreenshotsImage = () => {
  return (
    <Image
      alt="Birthday App Screenshots"
      data-cy="birthday-app-screenshots"
      src={MobileAppScreenshots}
    />
  );
};

export default function ResponsiveSplashScreen() {
  const { track } = useAnalytics();

  const trackAppStoreBadgePress = () => track('Press Webview App Store Badge');

  const trackPlayStoreBadgePress = () => track('Press Webview Play Store Badge');

  return (
    <Container
      bgGradient={{
        base: 'linear(to-t, skyBlue, purple)',
        md: 'none',
      }}
      borderTopLeftRadius={{ base: '100% 12%', md: '0' }}
      borderTopRightRadius={{ base: '100% 12%', md: '0' }}
      centerContent
      data-cy="app-store-bottom-banner"
      flexDir={{ base: 'column', md: 'column-reverse' }}
      mt={{ base: '97px', md: '0' }}
      pb="75px"
      pt={{ base: '57px', md: '0' }}
      px="0"
      maxW={{ base: '100vw', md: '50vw' }}
    >
      <Container centerContent mt={{ base: '0px', md: '15px' }}>
        <Container pl="43" pr="43">
          <Text
            color={{ base: 'white', md: 'black' }}
            fontSize="19"
            fontWeight="semibold"
            lineHeight="27"
            textAlign="center"
          >
            Get our app and turn your contacts into a birthday calendar. Free (no catch)!
          </Text>
        </Container>
        <Flex direction={{ base: 'column', md: 'row' }} mb="24px" mt="48px">
          <Link data-cy="app-store-badge" href={APP_STORE_LINK}>
            <AppStoreBadge onClick={trackAppStoreBadgePress} />
          </Link>
          <Link data-cy="play-store-badge" mt={{ base: '6', md: '0' }} href={PLAY_STORE_LINK}>
            <PlayStoreBadge onClick={trackPlayStoreBadgePress} />
          </Link>
        </Flex>
      </Container>
      <Flex alignItems="center" flexDir="column" mt={{ base: '6px', md: '28px' }} w="100%">
        <Box>
          <MobileAppScreenshotsImage />
        </Box>
        <Container display={{ md: 'none' }} maxW="280px">
          <Flex mt="18px">
            <PlusIcon color="darkGreyBlueTwo" />
            <Text color="white" fontSize="m" lineHeight="25" ml="12px" mt="-6px">
              We do the heavy lifting of finding your loved ones’ birthdays.
            </Text>
          </Flex>
          <Flex mt="34px">
            <PlusIcon color="darkGreyBlueTwo" />
            <Text color="white" fontSize="m" lineHeight="25" ml="12px" mt="-6px">
              Make everyone feel special with one click.
            </Text>
          </Flex>
          <Flex mt="27px">
            <PlusIcon color="darkGreyBlueTwo" />
            <Text color="white" fontSize="m" lineHeight="25" ml="12px" mt="-6px">
              Give and receive presents all year long.
            </Text>
          </Flex>
        </Container>
      </Flex>
    </Container>
  );
}
