import * as React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

type HeaderProps = IconProps & {
  size?: string;
};

export default function SiteHeader(props: HeaderProps) {
  return (
    <Icon
      data-cy="site-header"
      width="auto"
      height={props.size || '115'}
      viewBox="0 0 230 115"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_4049:2366)">
        <path
          fill="#FE5C19"
          d="M27.651 35.074c1.293 1.656 1.936 3.545 1.936 5.673 0 3.066-1.072 5.496-3.215 7.29-2.144 1.795-5.139 2.689-8.972 2.689H.284V14.04H16.82c3.733 0 6.652.856 8.764 2.562 2.112 1.706 3.164 4.023 3.164 6.95 0 2.16-.567 3.955-1.702 5.384-1.135 1.429-2.641 2.424-4.526 2.978 2.13.447 3.839 1.504 5.131 3.16zM9.236 28.88H15.1c1.463 0 2.591-.321 3.373-.97.788-.642 1.18-1.592 1.18-2.845 0-1.253-.392-2.21-1.18-2.871-.782-.661-1.91-.995-3.373-.995H9.236v7.681zm10.068 13.612c.82-.68 1.23-1.662 1.23-2.953 0-1.29-.429-2.298-1.28-3.028-.858-.73-2.03-1.096-3.53-1.096H9.235v8.097h6.588c1.5 0 2.66-.34 3.48-1.02z"
        ></path>
        <path
          fill="#FFC431"
          d="M43.715 21.563V50.72H34.77V21.563h8.946zm-9.11-11.043c-.435.976-.895 2.292-.542 3.84.473 2.06 2.264 3.728 4.363 4.05.265.037.523.056.788.056 1.86 0 3.638-.982 4.577-2.594.832-1.423.933-3.198.277-4.747L39.378 0l-4.773 10.52z"
        ></path>
        <path
          fill="#81E1B4"
          d="M63.076 22.634c1.57-.926 3.316-1.386 5.233-1.386v9.457H65.85c-2.231 0-3.908.478-5.024 1.435-1.116.957-1.677 2.638-1.677 5.043V50.72h-8.946V21.563h8.946v4.86c1.053-1.605 2.358-2.864 3.927-3.79z"
        ></path>
        <path
          fill="#36CFE7"
          d="M88.874 43.145v7.58h-4.552c-3.246 0-5.775-.793-7.584-2.38-1.816-1.586-2.724-4.173-2.724-7.762V28.986H70.46v-7.423h3.555v-7.108h8.947v7.108h5.863v7.423H82.96V40.69c0 .869.209 1.498.631 1.883.416.384 1.116.572 2.093.572h3.19z"
        ></path>
        <path
          fill="#8185E1"
          d="M119.855 24.566c2.023 2.21 3.032 5.251 3.032 9.117v17.036h-8.896V34.892c0-1.952-.504-3.47-1.519-4.546-1.015-1.077-2.37-1.618-4.079-1.618-1.708 0-3.07.541-4.079 1.618-1.015 1.083-1.519 2.594-1.519 4.546v15.834h-8.946V12.05h8.946v13.43c.908-1.291 2.143-2.318 3.713-3.086 1.57-.768 3.329-1.152 5.283-1.152 3.354.006 6.04 1.114 8.064 3.324z"
        ></path>
        <path
          fill="#FE5C19"
          d="M129.034 28.2c1.135-2.267 2.679-4.005 4.633-5.226 1.955-1.222 4.136-1.826 6.545-1.826 1.916 0 3.669.403 5.257 1.202 1.589.8 2.831 1.883 3.739 3.243V12.056h8.946v38.67h-8.946v-4.18c-.838 1.39-2.03 2.505-3.587 3.342-1.551.838-3.361 1.253-5.416 1.253-2.408 0-4.589-.617-6.544-1.857-1.954-1.234-3.499-2.997-4.634-5.276-1.134-2.28-1.702-4.917-1.702-7.914.006-2.997.574-5.628 1.709-7.895zm18.314 2.662c-1.235-1.29-2.749-1.933-4.526-1.933-1.778 0-3.291.636-4.527 1.908-1.236 1.272-1.86 3.022-1.86 5.25 0 2.23.618 3.999 1.86 5.302 1.236 1.303 2.749 1.958 4.527 1.958 1.777 0 3.284-.642 4.526-1.933 1.236-1.29 1.86-3.047 1.86-5.276 0-2.222-.618-3.985-1.86-5.276z"
        ></path>
        <path
          fill="#FFC431"
          d="M164.566 28.2c1.135-2.267 2.679-4.005 4.634-5.226 1.954-1.222 4.135-1.826 6.544-1.826 2.055 0 3.864.415 5.415 1.253 1.551.837 2.749 1.933 3.587 3.292v-4.13h8.947V50.72h-8.947v-4.13c-.87 1.36-2.086 2.456-3.637 3.293-1.551.837-3.361 1.253-5.416 1.253-2.37 0-4.533-.617-6.487-1.857-1.954-1.234-3.499-2.997-4.634-5.276-1.135-2.28-1.702-4.917-1.702-7.914-.006-2.99.561-5.622 1.696-7.889zm18.314 2.662c-1.235-1.29-2.748-1.933-4.526-1.933-1.778 0-3.291.636-4.527 1.908-1.236 1.272-1.86 3.022-1.86 5.25 0 2.23.618 3.999 1.86 5.302 1.236 1.303 2.749 1.958 4.527 1.958s3.284-.642 4.526-1.933c1.236-1.29 1.86-3.047 1.86-5.276-.006-2.222-.624-3.985-1.86-5.276z"
        ></path>
        <path
          fill="#81E1B4"
          d="M230 21.563L211.685 64.57h-9.626l6.695-14.84-11.878-28.167h9.993l6.752 18.24 6.695-18.24H230z"
        ></path>
        <path
          fill="#2E3757"
          d="M23.92 94.616H8.788L6.188 102H0l12.948-36.192h6.864L32.76 102h-6.24l-2.6-7.384zm-1.664-4.836L16.38 72.984 10.452 89.78h11.804zM44.318 77.56c1.005-1.317 2.375-2.427 4.108-3.328 1.733-.901 3.692-1.352 5.876-1.352 2.496 0 4.767.624 6.812 1.872 2.08 1.213 3.71 2.93 4.888 5.148 1.178 2.219 1.768 4.767 1.768 7.644s-.59 5.46-1.768 7.748c-1.179 2.253-2.808 4.021-4.888 5.304-2.045 1.248-4.316 1.872-6.812 1.872-2.184 0-4.126-.433-5.824-1.3-1.699-.901-3.086-2.01-4.16-3.328v17.784H38.39V73.348h5.928v4.212zm17.42 9.984c0-1.976-.416-3.675-1.248-5.096-.797-1.456-1.872-2.548-3.224-3.276a8.366 8.366 0 00-4.264-1.144c-1.49 0-2.912.381-4.264 1.144-1.318.763-2.392 1.872-3.224 3.328-.797 1.456-1.196 3.172-1.196 5.148 0 1.976.399 3.71 1.196 5.2.832 1.456 1.906 2.565 3.224 3.328 1.352.763 2.773 1.144 4.264 1.144a8.366 8.366 0 004.264-1.144c1.352-.797 2.427-1.941 3.224-3.432.832-1.49 1.248-3.224 1.248-5.2zM79.56 77.56c1.005-1.317 2.375-2.427 4.108-3.328 1.733-.901 3.692-1.352 5.876-1.352 2.496 0 4.767.624 6.812 1.872 2.08 1.213 3.709 2.93 4.888 5.148 1.179 2.219 1.768 4.767 1.768 7.644s-.589 5.46-1.768 7.748c-1.179 2.253-2.808 4.021-4.888 5.304-2.045 1.248-4.316 1.872-6.812 1.872-2.184 0-4.125-.433-5.824-1.3-1.699-.901-3.085-2.01-4.16-3.328v17.784h-5.928V73.348h5.928v4.212zm17.42 9.984c0-1.976-.416-3.675-1.248-5.096-.797-1.456-1.872-2.548-3.224-3.276a8.366 8.366 0 00-4.264-1.144c-1.49 0-2.912.381-4.264 1.144-1.317.763-2.392 1.872-3.224 3.328-.797 1.456-1.196 3.172-1.196 5.148 0 1.976.399 3.71 1.196 5.2.832 1.456 1.907 2.565 3.224 3.328 1.352.763 2.773 1.144 4.264 1.144a8.366 8.366 0 004.264-1.144c1.352-.797 2.427-1.941 3.224-3.432.832-1.49 1.248-3.224 1.248-5.2z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_4049:2366">
          <path fill="#fff" d="M0 0H230V115H0z"></path>
        </clipPath>
      </defs>
    </Icon>
  );
}
